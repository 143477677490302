import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    submitWait: false,
    inCart: []
  },
  getters: {
    inCart: state => state.inCart
  },
  mutations: {
    SUBMITWAIT_SETVALUE(state, boolean) {
      state.submitWait = boolean;
    },
    ADD_TO_CART(state, table) {
      state.inCart.push(table);
    }
  },
  actions: {
    setState(context, amount) {
      context.commit("SUBMITWAIT_SETVALUE", amount);
    },
    addToCart(context, table) {
      context.commit("ADD_TO_CART", table);
    }
  },
  modules: {}
});
