<template>
  <div class="upOthers">
    <v-container>
      <TitleAppBar v-bind:title="'A propos'" v-bind:menu="menu" />
    </v-container>

    <router-view></router-view>
  </div>
</template>

<script>
import TitleAppBar from "../../components/titleAppBar";

export default {
  name: "Festival",

  components: {
    TitleAppBar
  },

  data: () => ({
    menu: [
      { title: "Le Projet", href: "project" },
      { title: "Livre d'or", href: "guestbook" },
      { title: "La Troupe", href: "staff" }
    ]
  })
};
</script>
