<template>
  <v-footer
    padless
    color="primary"
    class="white--text py-10"
    style="z-index:10000;"
  >
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="7" md="6">
          <h4>Contactez-nous</h4>
          <v-list color="primary">
            <v-list-item
              v-for="contact in contacts"
              :key="contact.icon"
              class="pl-0"
            >
              <v-list-item-icon>
                <v-icon color="white"> ${{ contact.icon }} </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle class="white--text">{{ contact.text }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" sm="5" md="3" class="mt-2 mt-sm-0">
          <h4>Recevoir notre newsletter</h4>
          <NewsletterForm />
        </v-col>
        <v-col cols="12" md="3" class="text-right mt-2 mt-sm-0">
          <v-tooltip v-for="icon in reseaux" :key="icon.title" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ml-4"
                icon
                :href="icon.link"
                target="_blank"
              >
                <v-icon> ${{ icon.icon }} </v-icon>
              </v-btn>
            </template>
            <span>{{ icon.title }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import NewsletterForm from "./newsletterForm.vue";

export default {
  name: "Footer",

  components: {
    NewsletterForm
  },

  data: () => ({
    dialogNewsletter: false,
    contacts: [
      { icon: "mailIcon", text: "theatreduroidecoeur@mailo.com" },
      { icon: "phoneIcon", text: "06 62 56 86 17" },
      { icon: "positionIcon", text: "Allée du commissaire Félix Landry - 24100 Bergerac" }
    ],
    reseaux: [
      {
        icon: "facebookIcon",
        title: "Notre Facebook",
        externalLink: true,
        link: "https://www.facebook.com/theatreduroidecoeur"
      },
      {
        icon: "instaIcon",
        title: "Notre Instagram",
        externalLink: true,
        link: "https://www.instagram.com/theatreduroidecoeur/"
      }
    ]
  }),

  mounted() {
    document.getElementsByClassName(
      "subscribe-container__submit"
    ).subscribe.textContent = "envoyer";
    document.getElementsByClassName(
      "subscribe-container__email"
    ).EMAIL.placeholder = "votre adresse mail";
  },

  methods: {
    console(item) {
      console.log(item);
    }
  }
};
</script>

<style lang="scss">
.subscribe-container {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
  padding: 0 !important;
}
.subscribe-container__email {
  border-color: #e6e6e6;
  border-radius: 0;
}
.subscribe-container__submit {
  content: "";
  border-radius: 0 !important;
  background-color: var(--v-grey-base) !important;
  color: var(--v-blue-base) !important;
  height: 28px !important;
  padding: 0 14px !important;
  font-size: 0.8rem !important;
  font-family: "Montserrat" !important;
  font-weight: 500;
}
</style>
