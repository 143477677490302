<template>
  <v-container class="mb-12">
    <v-row v-for="(member, index) in members" :key="index">
      <v-col v-if="member.items.length > 0">
        <v-row class="mb-10">
          <v-col cols="12">
            <h3 class="text-h4 primary--text" v-text="member.title"></h3>
          </v-col>
          <v-col
            v-for="staff in member.items"
            :key="staff.id"
            lg="2"
            md="3"
            sm="4"
            cols="6"
          >
            <StaffCard
              v-bind:imgUrl="staff.acf.image ? staff.acf.image : false"
              v-bind:name="staff.title.rendered"
              v-bind:slug="staff.slug"
              v-bind:id="staff.id"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <TitleAppBar v-bind:title="'Nos bénévoles'" v-bind:littlePadding="true" />
    <p class="text-body-1">
      {{ volunteers }}
    </p>
  </v-container>
</template>

<script>
import TitleAppBar from "../../../components/titleAppBar";
import { APIService } from "../../../APIService";
import StaffCard from "../../../components/cards/staffCard";
const apiService = new APIService();

export default {
  name: "Staff",

  components: {
    StaffCard,
    TitleAppBar
  },

  data: () => ({
    members: {
      memberImportante: {
        title: "Les membres décisionnaires",
        items: []
      },
      memberDay: {
        title: "Les compagnons du jour",
        items: []
      },
      memberYesterday: {
        title: "Les compagnons d'hier",
        items: []
      }
    },
    volunteers: "",
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService
      .getAll(`wp/v2/staff/`)
      .then(data => {
        this.members.memberImportante.items = data.filter(
          d => d.acf.type.value == 0
        );
        this.members.memberDay.items = data.filter(d => d.acf.type.value == 1);
        this.members.memberYesterday.items = data.filter(
          d => d.acf.type.value == 2
        );
      })
      .catch(e => console.log(e));
    apiService.getAll("acf/v3/options/options?slug=project").then(data => {
      this.volunteers = data.acf.informations_groupe.volunteers;
    });
  }
};
</script>
