import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import store from "./store";
import * as VueGoogleMaps from "vue2-google-maps";
import VueRellax from "vue-rellax";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVA7fuEKnPRt36ynNtkMLNe1Ek_2WDtyo"
  },
  installComponents: true
});

Vue.use(VueRellax);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
