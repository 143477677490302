<template>
  <!-- <h2
    class="text-h5 primary--text mb-8 text-uppercase"
    v-text="'Plus d\'infos à venir très bientôt'"
  ></h2> -->
  <v-row>
    <v-row v-if="billetweb" class="mb-5">
      <v-col cols="3">
        <v-btn
          block
          rounded
          class="secondary mt-2"
          elevation="2"
          x-large
          href="https://www.billetweb.fr/pro/billetterie-festival-troc"
          target="_blank"
        >réserver</v-btn>
      </v-col>
      <v-col cols="5">
        <v-btn
          text
          color="primary"
          :to="{ name: 'politique' }"
          class="pt-12"
        >
          politique de billetterie
        </v-btn>
      </v-col>
    </v-row>
    
    <v-col cols="12" v-for="(date, index) in dates" :key="index" class="removeBorder">
      <v-row>
      <v-col cols="12">
        <h4 
          class="text-h5 primary--text" 
          v-text="date.day.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
        ></h4>
      </v-col>
      <v-col cols="12" xs="6" sm="4" md="3" class="itsMe" v-for="d in date.dates" :key="d.id">
        <ProgramCard 
          v-bind:play="d.link_play"
          v-bind:date="d.date"
          v-bind:place="d.place ? d.place : ''"
          v-bind:booking="d.booking"
        />
      </v-col>
      </v-row>
    </v-col>

    <v-row v-if="billetweb" class="mb-15">
      <v-col cols="3">
        <v-btn
          block
          rounded
          class="secondary mt-2"
          elevation="2"
          x-large
          :href="billetweblink"
          target="_blank"
        >réserver</v-btn>
      </v-col>
      <v-col cols="5">
        <v-btn
          text
          color="primary"
          :to="{ name: 'politique' }"
          class="pt-12"
        >
          politique de billetterie
        </v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import ProgramCard from "../../../components/cards/programCard"
import { APIService } from "../../../APIService";
const apiService = new APIService();
import moment from "moment";

export default {
  name: "Program",

  components: {
    ProgramCard
  },

  data: () => ({
    dates: [],
    billetweb: false,
    billetweblink: "",
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getAll("wp/v2/programmation").then(data => {
      var previewItem = "dddd DD MMMM";
      data[0].acf.dates.map(cd => {
        var day = this.formatDate(cd.date, "dddd DD MMMM");
        if (previewItem === day) {
          this.dates.find(d => d.day === day).dates.push(cd);
        } else {
          previewItem = this.formatDate(cd.date, "dddd DD MMMM");
          var calendarDates = [];
          calendarDates.push(cd);
          this.dates.push({ day: day, dates: calendarDates });
        }
      });
    });

    apiService.getAll("acf/v3/options/options?slug=informations").then(data => {
      this.billetweb = data.acf.activate_billeterie,
      this.billetweblink = data.acf.link_billetterie
    });
  },

  methods: {
    formatDate(data, format) {
      return moment(data, "MM/DD/YYYY HH[:]mm")
        .utc(false)
        .locale("fr")
        .format(format);
    }
  }
};
</script>

<style lang="scss" scoped>
.removeBorder .itsMe:last-child .v-card {
  border: none !important;
}
</style>
