<template>
  <div class="mt-2 mb-6">
    <a @click="$router.go(-1)">
      <v-icon>$arrowBack</v-icon>
      <span
        class="text-caption black--text ml-3"
        v-text="'Retour arrière'"
      ></span>
    </a>
  </div>
</template>

<script>
export default {
  name: "backArrow"
};
</script>
