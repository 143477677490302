var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-6",staticStyle:{"background-color":"transparent"},attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"4","sm":"2"}},[_c('p',{staticClass:"ma-0 text-caption",domProps:{"textContent":_vm._s(_vm.dateFormat(_vm.date, 'dddd'))}}),_c('p',{staticClass:"mx-0 my-3 text-h3 black--text",domProps:{"textContent":_vm._s(_vm.dateFormat(_vm.date, 'DD'))}}),_c('p',{staticClass:"mx-0 mb-0 text-subtitle-1 font-weight-medium",domProps:{"textContent":_vm._s(_vm.dateFormat(_vm.date, 'MMM'))}}),_c('p',{staticClass:"mx-0 mb-1 text-subtitle-2",domProps:{"textContent":_vm._s(_vm.dateFormat(_vm.date, 'H[h]mm'))}}),_c('p',{staticClass:"ma-0 text-caption",domProps:{"textContent":_vm._s(_vm.city)}}),_c('p',{staticClass:"ma-0 text-caption",domProps:{"textContent":_vm._s(_vm.place)}})]),_c('v-col',{staticClass:"ma-0 d-none d-sm-block",attrs:{"cols":"4","md":"4","lg":"3"}},[_c('v-img',{attrs:{"height":"200","src":_vm.thePlay.acf.image}})],1),_c('v-col',{attrs:{"cols":"8","sm":"6","md":"4"}},[_c('a',{attrs:{"href":_vm.$router.resolve({
            name: 'playItem',
            params: { slug: _vm.thePlay.slug, id: _vm.thePlay.id, type: _vm.type }
          }).href}},[_c('h3',{staticClass:"text-h5 hoverTitle font-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('p',{staticClass:"text-body-2 mt-5 black--text"},[_c('span',{domProps:{"innerHTML":_vm._s(
            `${_vm.thePlay.content.rendered.slice(0, 200)}
        <a 
          href='
            ${
              _vm.$router.resolve({
                name: 'playItem',
                params: { slug: _vm.thePlay.slug, id: _vm.thePlay.id, type: _vm.type }
              }).href
            }
          '
          class='blue--text font-weight-medium font-italic'
        >lire la suite</a>`
          )}})])]),_c('v-spacer',{staticClass:"d-none d-md-block"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }