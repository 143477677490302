<template>
  <v-card elevation="0" style="background-color:transparent;">
    <a
      :href="
        $router.resolve({
          name: 'playItem',
          params: { slug: slug, id: id, type: 'spectacle' }
        }).href
      "
    >
      <v-img
        height="230"
        :src="imgUrl"
      ></v-img>

      <h3
        class="text-h5 primary--text font-weight-bold my-3"
        v-html="title"
      ></h3>

      <v-chip
        v-if="information !== ''"
        label
        outlined
        small
        style="border-radius:0 !important"
        >{{ information }}</v-chip
      >

      <p class="text-body-2 mt-2 textcolor black--text">
        <span
          v-html="
            `${text.slice(0, 200) + '...'}
        <a 
          href='
            ${
              $router.resolve({
                name: 'playItem',
                params: { slug: slug, id: id, type: 'spectacle' }
              }).href
            }
          '
          class='blue--text font-weight-medium font-italic'
        >lire la suite</a>`
          "
        ></span>
      </p>
    </a>
  </v-card>
</template>

<script>
export default {
  name: "PlayCard",
  props: ["title", "text", "imgUrl", "information", "slug", "id"]
};
</script>

<style lang="scss" scope>
.v-chip {
  border-color: #0b3753 !important;
  color: #0b3753 !important;
}
</style>
