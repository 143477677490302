<template>
  <v-card
    elevation="0"
    style="background-color:transparent;border-radius:0;border-right:1px solid black;"
    class="pr-4 mb-10"
  >
    <p
      v-text="dateFormat(date, 'HH:mm')"
      class="mx-0 mb-0 text-h4 font-weight-medium"
    ></p>
    <p
      v-text="play.post_title"
      class="mx-0 mt-3 mb-0 text-subtitle-2 black--text"
    ></p>
    <p v-text="place" class="mb-1 text-caption"></p>
    <v-chip
      v-if="information !== ''"
      label
      outlined
      x-small
      style="border-radius:0 !important"
      class="mb-2"
    >{{ information }}</v-chip>
    <v-img height="120" :src="theImg"></v-img>
    <p class="text-caption mt-2 black--text">
      <span
        v-html="
          `${play.post_content.slice(0, 150) + '...'}
      <a 
        href='
          ${
            $router.resolve({
              name: 'playItem',
              params: { slug: play.post_name, id: play.ID, type: 'spectacle' }
            }).href
          }
        '
        class='blue--text font-weight-medium font-italic'
      >lire la suite</a>`
        "
      ></span>
    </p>
  </v-card>
</template>

<script>
import moment from "moment";
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "ProgramCard",
  props: ["play", "date", "place", "imgUrl", "booking"],

  data: () => ({
    theImg: "",
    information: ""
  }),

  mounted() {
    apiService.getById("acf/v3/plays", this.play.ID).then(data => {
      this.theImg = data.acf.image;
      this.information = data.acf.information
    });
  },

  methods: {
    dateFormat(data, format) {
      return moment(data).format(format);
    }
  }
};
</script>
