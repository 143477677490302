<template>
  <div class="upOthers pb-12 mb-12">
    <v-container class="text-center">
      <h3 class="text-h5 primary--text">Livre d'or</h3>
      
      <p class="text-center text-subtitle-1 font-weight-bold mt-8">Vous avez aimé un de nos spectacles ?</p>
      <p class="text-center text-subtitle-2 mt-0">Laissez nous un commentaire, il sera peut-être sélectionné pour apparaître<br>sur notre page d’accueil!</p>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="mx-auto mb-12"
        style="max-width:600px"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              placeholder="Pseudo*"
              outlined
              color="primary"
              hide-details
              v-model="message.title"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              placeholder="Code Postal*"
              outlined
              color="primary"
              hide-details
              v-model="message.zip_code"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              placeholder="Ville*"
              outlined
              color="primary"
              hide-details
              v-model="message.city"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              placeholder="Votre message*"
              outlined
              color="primary"
              hide-details
              rows="4"
              v-model="message.text"
              required
            ></v-textarea>
            <input-hidden label="test*" v-model="message.test" />
          </v-col>
          <v-col cols="12">
            <input-hidden label="test*" v-model="message.test" />
          </v-col>
        </v-row>

        <v-btn
          plain
          rounded
          class="primary"
          elevation="2"
          :disabled="!valid"
          @click="submit()"
        >
          envoyer
        </v-btn>
      </v-form>
    </v-container>
    <CardsSlider v-bind:howManyComent="30" />
  </div>
</template>

<script>
import CardsSlider from "../../../components/slider/cardsSlider"
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "Festival",

  components: {
    CardsSlider
  },

  data: () => ({
    message: {
      city: "",
      zip_code: "",
      text: "",
      title: ""
    }
  }),

  methods: {
    submit() {
      console.log(this.message)
      apiService.create("wp/v2/gold_book", this.message);
    }
  }
};
</script>
