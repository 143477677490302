<template>
  <div>
    <div class="backgroundGrey my-15 py-5">
      <v-container>
        <v-row align="center">
          <v-col cols="12" sm="6" class="text-center text-sm-right pr-10">
            <h1 v-if="principal" class="text-h2" v-text="title"></h1>
            <h3 v-else class="text-h2" v-text="title"></h3>
            <BlackLine />
            <p
              v-if="urlItem === true"
              class="mt-2 mb-10"
              v-html="
                `${returnText(subTitle)}
                  <a 
                    href='
                      ${
                        $router.resolve({
                          name: 'actuItem',
                          params: { slug: url.slug, id: url.id }
                        }).href
                      }
                    '
                    class='blue--text font-weight-medium font-italic'
                  >lire la suite</a>`
              "
            ></p>
            <p v-else class="mt-2 mb-10" v-html="subTitle"></p>
            <v-btn v-if="ticketOpen" depressed outlined plain rounded :href="ticketLink">{{
              "réserver"
            }}</v-btn>
            <v-btn v-else depressed outlined plain rounded :to="{ name: secondUrl }">{{
              btnTitle
            }}</v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="px-10 d-none d-sm-block">
            <div
              class="absoluteImage"
              v-rellax="{ speed: 2, percentage: 10 }"
            >
              <a 
                :href="ticketOpen ? ticketLink : $router.resolve({
                      name: 'actuItem',
                      params: { slug: url.slug, id: url.id }
                    }).href"   
                class='blue--text font-weight-medium font-italic'
              >
                <v-img height="400" :src="imgUrl"></v-img>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { APIService } from "../../APIService";
const apiService = new APIService();
import BlackLine from "../../assets/icos/BlackLine.vue";

export default {
  name: "ContainerBackgroundGrey",
  props: [
    "imgUrl",
    "title",
    "subTitle",
    "btnTitle",
    "principal",
    "url",
    "urlItem",
    "secondUrl",
    "ticketOpen",
    "ticketLink"
  ],

  components: {
    BlackLine
  },

  data: () => ({
    ticketOpen: false,
    ticketLink: "",
  }),

  mounted() {
    apiService.getAll("acf/v3/options/options?slug=project").then(data => {
      this.ticketOpen = data.acf.activate_billeterie
      this.ticketLink = data.acf.link_billetterie
    })
  },

  methods: {
    returnText(text) {
      var cutlink = text.indexOf("<a class");
      return text.slice(0, cutlink);
    }
  }
};
</script>

<style lang="scss" scope>
.backgroundGrey {
  position: relative;
  background-color: var(--v-grey-base);
}
.lineBottom {
  position: relative;
  top: -10px;
}
.absoluteImage {
  margin-top: 50px;
  max-width: 100%;
}
</style>
