<template>
  <v-container class="upOthers">
    <BackArrow />

    <v-row no-gutters>
      <v-col cols="12" sm="8" lg="9">
        <v-img height="400" :src="play.acf.image"></v-img>
        <h1
          class="text-h4 primary--text mt-8"
          v-html="play.title.rendered"
        ></h1>
        <h3 class="text-h6 secondary--text mt-2" v-html="play.acf.author"></h3>

        <v-row class="my-4">
          <v-col v-if="play.acf.information" cols="auto"
            ><v-icon>$peopleYellow</v-icon></v-col
          >
          <v-col
            v-if="play.acf.information"
            cols="auto"
            class="mr-6 primary--text font-weight-bold"
            >{{ play.acf.information }}</v-col
          >
          <v-col v-if="play.acf.create_date" cols="auto"
            ><v-icon>$dateYellow</v-icon></v-col
          >
          <v-col
            v-if="play.acf.create_date"
            cols="auto"
            class="mr-6 primary--text font-weight-bold"
            >{{ play.acf.create_date }}</v-col
          >
          <v-col v-if="play.acf.duration" cols="auto"
            ><v-icon>$clock</v-icon></v-col
          >
          <v-col
            v-if="play.acf.duration"
            cols="auto"
            class="mr-6 primary--text font-weight-bold"
            >{{ play.acf.duration }}</v-col
          >
        </v-row>

        <p class="text-body-1" v-html="play.content.rendered"></p>

        <!-- METTEUR EN SCENE -->
        <v-row
          class="backgroundGrey my-10"
          v-if="
            this.$route.params.type === 'spectacle' &&
              play.acf.linked_real_play &&
              play.acf.note_dintention !== ''
          "
        >
          <v-col
            cols="3"
            class="d-flex align-center justify-center flex-column"
          >
            <v-avatar size="80px">
              <v-img
                v-if="real.acf.image"
                :alt="`avatar - ${real.title.rendered}`"
                :src="real.acf.image"
              ></v-img>
              <span
                v-else
                class="white--text text-h5"
                v-text="real.title.rendered.slice(0, 1)"
              ></span>
            </v-avatar>
            <span
              class="text-subtitle-2 mt-2 text-center"
              v-text="real.title.rendered"
            ></span>
            <span class="text-caption" v-text="'Metteur en scène'"></span>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="" class="coteMS left">
                <span class="white--text">“</span>
              </v-col>
              <v-col cols="9">
                <p class="text-body-2" v-html="play.acf.note_dintention"></p>
              </v-col>
              <v-col cols="" class="coteMS right">
                <span class="white--text">”</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- END METTEUR EN SCENE -->

        <!-- ROLES -->
        <div class="mb-10 mt-5">
          <v-row
            v-if="
              this.$route.params.type === 'spectacle' &&
                play.acf.linked_play_roles
            "
          >
            <v-col cols="4">
              <h6
                class="primary--text text-subtitle-2"
                v-text="'Metteur en scène'"
              ></h6>
            </v-col>
            <v-col cols="8">
              <!-- add metteur en scene -->
              <p
                class="text-body-2"
                v-text="real.title.rendered"
              ></p>
            </v-col>
          </v-row>
          <v-row v-if="this.$route.params.type === 'spectacle'">
            <v-col v-if="play.acf.linked_play_roles.length > 0" cols="4">
              <h6
                class="primary--text text-subtitle-2"
                v-text="'Comédiens'"
              ></h6>
            </v-col>
            <v-col cols="8">
              <p
                class="text-body-2 mb-2"
                v-for="role in play.acf.linked_play_roles"
                :key="role.id"
                v-text="role.post_title"
              ></p>
            </v-col>
          </v-row>
          <v-row v-for="trole in play.acf.technical_roles" :key="trole.id">
            <!-- Groupe by  -->
            <v-col cols="4">
              <h6
                class="primary--text text-subtitle-2"
                v-text="trole.role_name"
              ></h6>
            </v-col>
            <v-col cols="8">
              <p class="text-body-2" v-text="trole.name"></p>
            </v-col>
          </v-row>
        </div>
        <!-- END ROLES -->

        <!-- VIDEO -->
        <div v-if="play.video !== ''" class="mb-10 mt-5">
          <v-responsive :aspect-ratio="16 / 9">
            <iframe
              width="100%"
              height="100%"
              :src="play.acf.lien_video"
              :title="play.title.rendered"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-responsive>
        </div>
      </v-col>

      <!-- calendar part -->
      <v-col cols="12" sm="4" lg="3">
        <div class="stickyCalendar" v-if="dates.length > 0">
          <DateCard v-bind:calendars="dates" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIService } from "../../../APIService";
const apiService = new APIService();
import DateCard from "../../../components/cards/dateCard";
import BackArrow from "../../../components/backArrow";

export default {
  name: "PlayItem",

  components: {
    DateCard,
    BackArrow
  },

  data: () => ({
    play: [],
    real: {},
    dates: []
  }),

  mounted() {
    apiService
      .getById(
        this.$route.params.type === "evenement" ? "wp/v2/event" : "wp/v2/plays",
        this.$route.params.id
      )
      .then(data => {
        this.play = data;
        if (data.acf.linked_real_play) {
          apiService
            .getById(`wp/v2/staff`, data.acf.linked_real_play[0].ID)
            .then(response => {
              this.real = response;
            });
        }
        apiService.getAll(`wp/v2/calendar`).then(response => {
          this.dates = response.filter(
            filter =>
              filter.acf.event.post_type === data.type &&
              filter.acf.event.ID === data.id
          );
        });
      });
  }
};
</script>

<style lang="scss" scoped>
.coteMS {
  position: relative;
  span {
    position: absolute;
    font-family: "PT Sans";
    font-size: 9rem;
    line-height: 70px;
  }
}
.coteMS.left {
  span {
    top: 30px;
  }
}
.coteMS.right {
  span {
    bottom: -30px;
    right: 10px;
  }
}
.stickyCalendar {
  position: sticky;
  top: 80px;
}
</style>
