var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"backgroundGrey my-15 py-5"},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center text-sm-right pr-10",attrs:{"cols":"12","sm":"6"}},[(_vm.principal)?_c('h1',{staticClass:"text-h2",domProps:{"textContent":_vm._s(_vm.title)}}):_c('h3',{staticClass:"text-h2",domProps:{"textContent":_vm._s(_vm.title)}}),_c('BlackLine'),(_vm.urlItem === true)?_c('p',{staticClass:"mt-2 mb-10",domProps:{"innerHTML":_vm._s(
              `${_vm.returnText(_vm.subTitle)}
                <a 
                  href='
                    ${
                      _vm.$router.resolve({
                        name: 'actuItem',
                        params: { slug: _vm.url.slug, id: _vm.url.id }
                      }).href
                    }
                  '
                  class='blue--text font-weight-medium font-italic'
                >lire la suite</a>`
            )}}):_c('p',{staticClass:"mt-2 mb-10",domProps:{"innerHTML":_vm._s(_vm.subTitle)}}),(_vm.ticketOpen)?_c('v-btn',{attrs:{"depressed":"","outlined":"","plain":"","rounded":"","href":_vm.ticketLink}},[_vm._v(_vm._s("réserver"))]):_c('v-btn',{attrs:{"depressed":"","outlined":"","plain":"","rounded":"","to":{ name: _vm.secondUrl }}},[_vm._v(_vm._s(_vm.btnTitle))])],1),_c('v-col',{staticClass:"px-10 d-none d-sm-block",attrs:{"cols":"12","sm":"6"}},[_c('div',{directives:[{name:"rellax",rawName:"v-rellax",value:({ speed: 2, percentage: 10 }),expression:"{ speed: 2, percentage: 10 }"}],staticClass:"absoluteImage"},[_c('a',{staticClass:"blue--text font-weight-medium font-italic",attrs:{"href":_vm.ticketOpen ? _vm.ticketLink : _vm.$router.resolve({
                    name: 'actuItem',
                    params: { slug: _vm.url.slug, id: _vm.url.id }
                  }).href}},[_c('v-img',{attrs:{"height":"400","src":_vm.imgUrl}})],1)])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }