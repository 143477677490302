<template>
  <div>
    <v-container class="upOthers">
      <TitleAppBar v-bind:title="'Le Festival'" v-bind:menu="menu" />

      <router-view></router-view>
    </v-container>
    <div v-if="this.$route.name === 'festivalInfo'" class="greyPart"></div>
  </div>
</template>

<script>
import TitleAppBar from "../../components/titleAppBar";

export default {
  name: "Festival",

  components: {
    TitleAppBar
  },

  data: () => ({
    // menu: [{title: 'Infos Pratiques', href: 'festivalInfo'}],
    menu: [
      { title: "Infos Pratiques", href: "festivalInfo" },
      { title: "à l'affiche", href: "festivalPlay" },
      { title: "Programme", href: "festivalProgram" }
    ]
  })
};
</script>

<style lang="scss" scoped>
.greyPart {
  background-color: var(--v-grey-base);
  height: 220px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
}
</style>
