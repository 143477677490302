<template>
  <div
    :class="
      `d-flex ${
        littlePadding ? 'mt-15' : 'mt-15 mb-8'
      } flex-column flex-md-row align-md-end upOthers`
    "
  >
    <div>
      <h1 class="text-h2" v-text="title"></h1>
      <BlackLine />
    </div>
    <div class="flex-grow-1"></div>
    <div>
      <v-toolbar color="transparent" flat>
        <v-tabs active-class="removeActive" color="transparent">
          <v-tab
            v-for="(m, index) in menu"
            :key="index"
            class="text-lowercase"
            :to="{ name: m.href }"
          >
            {{ m.title }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import BlackLine from "../../assets/icos/BlackLine.vue";

export default {
  name: "TitleAppBar",
  props: ["title", "menu", "littlePadding"],

  components: {
    BlackLine
  }
};
</script>

<style lang="scss" scoped>
.removeActive {
  color: black !important;
}
.v-tabs-slider-wrapper {
  color: rgba(0, 0, 0, 0) !important;
}
.v-tab:hover::before,
.v-tab::after,
.v-tab:focus::before,
.v-tab::before {
  background-color: transparent !important;
}
.v-tabs-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>
