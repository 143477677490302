<template>
  <v-container class="upOthers" style="overflow:hidden">
    <TitleAppBar v-bind:title="'Actualités'" />

    <div ref="infoBox">
      <div
        v-for="(actu, index) in actualities"
        :key="actu.id"
        style="padding-bottom:100px"
      >
        <ActuCard
          v-bind:index="index"
          v-bind:imgUrl="actu.acf.image"
          v-bind:alignR="index % 2 == 0 ? false : true"
          v-bind:slug="actu.slug"
          v-bind:id="actu.id"
          v-bind:text="actu.excerpt.rendered"
          v-bind:title="actu.title.rendered"
        />
      </div>
    </div>

    <div
      class="line"
      :style="
        `${leftColStyles};left:${
          $vuetify.breakpoint.smAndDown ? '32px' : 'calc(50% - 0.5px)'
        };`
      "
    ></div>
  </v-container>
</template>

<script>
import TitleAppBar from "../../components/titleAppBar";
import ActuCard from "../../components/cards/actuCard";
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "News",

  components: {
    TitleAppBar,
    ActuCard
  },

  data: () => ({
    actualities: [],
    apipath: apiService.APIurl(),
    leftColStyles: ""
  }),

  methods: {
    matchHeight() {
      var heightString = this.$refs.infoBox.offsetTop + "px";
      this.leftColStyles = "top:" + heightString;
    }
  },

  mounted() {
    apiService.getAll("wp/v2/posts").then(data => {
      this.actualities = data;
    });
    this.matchHeight();
  }
};
</script>

<style lang="scss" scoped>
.line {
  position: absolute;
  z-index: 1;
  width: 1px;
  background-color: black;
  height: 100%;
}
</style>
