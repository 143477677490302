var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`d-flex align-center ${_vm.alignR == false ? 'flex-row' : 'flex-row-reverse'}`,staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-none d-sm-block",staticStyle:{"width":"calc(50% - 20px)"}},[_c('div',{class:`bg_image ${_vm.alignR == false ? 'mr-12' : 'ml-12'}`,style:(`background-image:url(${_vm.imgUrl});`)})]),_c('div',{class:`d-flex ${
        _vm.alignR == false || _vm.$vuetify.breakpoint.smAndDown
          ? 'flex-row'
          : 'flex-row-reverse'
      }`,style:(`width:${_vm.$vuetify.breakpoint.smAndDown ? '100%' : 'calc(50% + 20px)'};`)},[_c('div',{staticStyle:{"width":"40px"}},[_c('div',{ref:"height",staticClass:"yellow_round mx-auto"})]),_c('div',{staticStyle:{"width":"calc(100% - 40px)"}},[_c('div',{class:`${
            _vm.alignR == false || _vm.$vuetify.breakpoint.smAndDown
              ? 'ml-12 text-left'
              : 'mr-12 text-right'
          }`},[_c('a',{attrs:{"href":_vm.$router.resolve({
              name: 'actuItem',
              params: { slug: _vm.slug, id: _vm.id }
            }).href}},[_c('h3',{staticClass:"text-h5 hoverTitle font-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('p',{staticClass:"text-body-2 mt-5 textcolor"},[_c('span',{domProps:{"innerHTML":_vm._s(
              `${_vm.returnText(_vm.text)}
          <a 
            href='
              ${
                _vm.$router.resolve({
                  name: 'actuItem',
                  params: { slug: _vm.slug, id: _vm.id }
                }).href
              }
            '
            class='blue--text font-weight-medium font-italic'
          >lire la suite</a>`
            )}})])])])]),(_vm.index === 0)?_c('div',{staticClass:"hackTheSystem",style:(_vm.heightStyle)}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }