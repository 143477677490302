<template>
  <v-container class="mb-12 upOthers">
    <BackArrow />

    <v-row>
      <v-col cols="12" sm="4">
        <v-img width="100%" :src="staff.acf.image" :aspect-ratio="1.2"></v-img>
      </v-col>
      <v-col cols="12" sm="8">
        <h1 class="text-h5 primary--text" v-text="staff.title.rendered"></h1>
        <h3
          class="text-subtitle-1 black--text font-weight-bold mb-5"
          v-text="staff.acf.role"
        ></h3>
        <p class="text-boy-2" v-html="staff.content.rendered"></p>
      </v-col>
    </v-row>

    <!-- <div v-if="staff.plays.length > 0">
      <TitleAppBar v-bind:title="'Mise en Scène'" v-bind:littlePadding="true" />
      <v-row>
        <v-col
          v-for="play in staff.plays"
          :key="play.id"
          cols="12"
          sm="6"
          md="4"
        >
          <PlayRolesCard 
            v-bind:slug="play.slug" 
            v-bind:id="play.id"
            v-bind:title="play.title"
            v-bind:imgUrl="`${apipath}${play.photo.moyen}`"
          />
        </v-col>
      </v-row>
    </div>

    <div v-if="staff.roles.length > 0">
      <TitleAppBar v-bind:title="'Rôles'" v-bind:littlePadding="true" />
      <v-row>
        <v-col
          v-for="role in staff.roles"
          :key="role.id"
          cols="12"
          sm="6"
          md="4"
        >
          <PlayRolesCard 
            v-bind:slug="role.play.slug" 
            v-bind:id="role.play.id"
            v-bind:title="role.play.title"
            v-bind:imgUrl="`${apipath}${role.play.photo.moyen}`"
          />
        </v-col>
      </v-row>
    </div> -->
  </v-container>
</template>

<script>
// import PlayRolesCard from "../../../components/cards/playRolesCard"
// import TitleAppBar from "../../../components/titleAppBar"
import BackArrow from "../../../components/backArrow";
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "StaffItem",

  components: {
    // TitleAppBar,
    // PlayRolesCard,
    BackArrow
  },

  data: () => ({
    staff: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getById("wp/v2/staff", this.$route.params.id).then(data => {
      this.staff = data;
    });
  }
};
</script>
