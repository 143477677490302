<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        color="primary"
        elevation="5"
        style="border-radius:20px;width:100%;"
        @click="toggle"
        v-bind="attrs"
        v-on="on"
      >
        <v-row class="mx-4" style="height:250px">
          <v-col cols="12" class="cote left">
            <span class="white--text">“</span>
          </v-col>
          <v-col cols="12" style="height:110px">
            <p
              class="text-caption white--text font-weight-bold"
              v-html="body.slice(0, 180) + '...'"
            ></p>
          </v-col>
          <v-col cols="6">
            <div class="line"></div>
          </v-col>
          <v-col cols="6" class="cote right">
            <span class="white--text">”</span>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-list-item class="grow">
            <v-list-item-avatar color="white">
              <span
                class="primary--text font-weight-bold text-h6 text-uppercase"
                v-text="pseudo.charAt(0)"
              ></span>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="text-body-2 white--text font-weight-bold"
              >{{ pseudo }}</v-list-item-title>
              <span
                class="text-caption white--text d-none d-lg-block"
                v-text="city + ' ' + zip"
              ></span>
              <span
                class="text-caption font-italic white--text"
                v-text="dateFormat(date)"
              ></span>
            </v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </template>

    <v-card>
      <v-card-title
        class="text-subtitle-h6 primary--text font-weight-bold grey mb-3"
      >{{ pseudo }}</v-card-title>

      <v-card-text>{{ body }}</v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "GuestbookCard",
  props: ["pseudo", "date", "city", "zip", "body", "toggle"],

  data: () => ({
    dialog: false
  }),

  methods: {
    dateFormat(data) {
      return moment(data)
        .utc()
        .locale("fr")
        .format("DD/MM/YYYY HH:mm");
    }
  }
};
</script>

<style lang="scss" scope>
.cote {
  position: relative;
  span {
    position: absolute;
    font-family: "PT Sans";
    font-size: 9rem;
    line-height: 70px;
  }
}
.cote.left {
  height: 70px;
  span {
    top: 50px;
  }
}
.cote.right {
  height: 70px;
  span {
    top: 50px;
    right: 10px;
  }
}
.line {
  height: 2px;
  background-color: white;
  position: relative;
  top: 40px;
}
</style>
