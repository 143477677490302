<template>
  <v-container class="upOthers">
    <v-row>
      <v-col cols="12" md="8">

        <h2 class="text-h4 primary--text mt-8 mb-4">RÉSERVATIONS</h2>

        <h4 class="text-h6 primary--text mt-8 mb-4"><b>COMMENT RÉSERVER ?</b></h4>
        <v-row class="mb-5">
          <v-col cols="4">
            <p class="text-body-1"><b>En ligne</b></p>
          </v-col>
          <v-col cols="8">
            <p class="text-body-1">Via la plateforme de réservation intégrée à notre site internet. <b>Paiement par CB</b></p>
          </v-col>

          <v-col cols="4">
            <p class="text-body-1"><b>Au bureau</b></p>
          </v-col>
          <v-col cols="8">
            <p class="text-body-1">Organisation de trois permanences entre Bergerac (Foyer du TROC) et Maurens pendant les répétitions de
              juillet. <b>Paiement en chèque ou en espèces</b></p>
          </v-col>
        </v-row>

        <h4 class="text-h6 primary--text mt-8 mb-4"><b>POLITIQUE DE LA BILLETTERIE</b></h4>
        <h6 class="text-subtitle-2"><b>Validation de la réservation</b></h6>
        <p class="text-body-1 mb-8">
          <ul>
            <li>Une réservation est validée lorsque le paiement est effectuée.</li>
            <li>Une réservation sur le site internet entraîne un paiement par CB. Cela à un coût (0,29ct + 1% du prix du billet) qui
              sera divisé entre le spectateur et la compagnie. 50% des frais de comission seront ainsi ajoutés par billet réservé.</li>
            <li>Le règlement des places effectué au bureau de Maurens et Bergerac par chèques ou espèces n'entraîneront pas de frais de réservation
              supplémentaires.</li>
            <li>Le placement en salle entraîne un surcoût à la charge du spectateur.</li>
          </ul>
        </p>

        <h6 class="text-subtitle-2"><b>Horaire des spectacles</b></h6>
        <p class="text-body-1 mb-8">
          <ul>
            <li>Les spectacles commencent à l'heure précise. Les portes de la salle ouvrent 1h avant le début du spectalce, sauf contre-indication
              artistique.</li>
            <li>Les réservations pré-payées ont l'assurance de leur place jusqu'au début de la représentation. Passée cette heure, les retardataires ne
              sont plus prioritaires et leurs places ne sont plus garanties. Pas de remboursement possible.</li>
          </ul>
        </p>

        <h6 class="text-subtitle-2"><b>Remboursement et échange</b></h6>
        <p class="text-body-1 mb-8">
          <ul>
            <li>Les billets ne peuvent être ni repris, ni remboursés moins de 48h avant la date de représentation.
              <i>Ex : si la représentation a lieu le vendredi à 20h, aucun remboursement ne sera appliqué passé 20h du mercredi qui le précède.</i></li>
            <li>Les billets réservés en ligne seront remboursés en enlevant du remboursement le prix total de la commission du site
              internet pour chaque billet : 0,29ct + 1% du prix du billet, ainsi que la commission du placement.</li>
            <li>Que ce soit via le site internet ou auprès de nous, vous aurez la possibilité de ne demander le remboursement que d'un seul billet si
              vous en avez commandé plusieurs. Ainsi, cela n'entrainera pas le remboursement total de votre commande et donc de vos autres
              réservations.</li>
            <li>Les billets peuvent être échangés en fonction de la disponibilité des autres dates de la programmation.</li>
          </ul>
        </p>

        <h6 class="text-subtitle-2"><b>Annulation ou report d'une représentation</b></h6>
        <p class="text-body-1 mb-8">
          <ul>
            <li>En cas d'annulation ou report en amont de la représentation du fait du Festival du Théâtre du Roi de Coeur, les places seront
              remboursées si la personne n'a pas la possibilité d'assister à un autre spectacle de la programmation.</li>
            <li>L'interruption de la représentation en cours ne peut donner lieu à un remboursement.</li>
          </ul>
        </p>

        <h6 class="text-subtitle-2"><b>Adhésions</b></h6>
        <p class="text-body-1 mb-8">
          <ul>
            <li>Les adhésions sont strictement nominatives et ne donnent droit à un tarif préférentiel sur le festival qu'à leur détenteur.</li>
          </ul>
        </p>

        <h5 class="text-subtitle-1"><b>Nouvelle tarification</b></h5>
        <p class="text-body-1 mb-8">
          <ul>
            <li>Il est extrêmement important pour nous de rester fidèles à notre promesse d'accessibilité. Les places de cette 9ème édition restent
              donc à 5 euros en tarif réduit pour les adhérents (de la saison 2022-2023) et le tarif plein est à 10 euros... en première option.</li>
            <li>Cette année, nous vous proposons en effet de nous soutenir, si vous le souhaitez, et si vos moyens vous le permettent, en donnant<br>
              davantage. Ainsi, le tarif plein est, au choix, à 10, 15 ou 20 euros.<br>
              De même,les adhésions sont, au choix, à 10, 20 ou 30 euros<br>
              (gratuit pour les -18 ans).<br>
              Pour tout ce que vous faites pour le festival, MERCI !</li>
          </ul>
        </p>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "politique",
};
</script>

<style lang="scss" scoped>

</style>