<template>
  <div class="upOthers">
    <v-container>
      <TitleAppBar v-bind:title="'Le Foyer'" v-bind:menu="menu" />

      <router-view></router-view>
    </v-container>
    <div v-if="this.$route.name === 'housePlace'">
      <GmapMap
        :center="{ lat: 44.84338, lng: 0.49253 }"
        :zoom="15"
        map-type-id="terrain"
        style="width: 100%; height: 350px"
      >
        <GmapMarker
          :position="{ lat: 44.84338, lng: 0.49253 }"
          :clickable="true"
          @click="center = { lat: 44.84338, lng: 0.49253 }"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import TitleAppBar from "../../components/titleAppBar";

export default {
  name: "House",

  components: {
    TitleAppBar
  },

  data: () => ({
    menu: [
      { title: "Le Lieu", href: "housePlace" },
      { title: "La Bibliothèque", href: "houseLibrary" }
    ]
  })
};
</script>
