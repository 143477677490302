<template>
  <div class="backgroundGreySlide">
    <div class="slideAbsolute">
      <v-slide-group
        v-model="model"
        class="slideWidth my-8 mx-auto"
        center-active
        show-arrows
        next-icon="$nextArrowIcon"
        prev-icon="$prevArrowIcon"
        style="width:1154px;"
        :style="
          `width:${
            $vuetify.breakpoint.xs
              ? 404
              : $vuetify.breakpoint.mdAndDown
              ? 1004
              : 1154
          }px`
        "
      >
        <v-slide-item
          v-for="message in lastGuestbookMessage"
          :key="message.id"
          v-slot="{ active, toggle }"
          :style="`width:${$vuetify.breakpoint.mdAndDown ? 300 : 350}px`"
        >
          <div class="pa-5">
            <GuestbookCard
              v-bind:pseudo="message.title.rendered"
              v-bind:body="message.acf.text"
              v-bind:city="message.acf.city"
              v-bind:zip="message.acf.zip_code"
              v-bind:date="message.date"
              v-bind:toggle="toggle"
              v-bind:active="active"
            />
          </div>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import GuestbookCard from "../cards/guestbookCard.vue";
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "CardsSlider",
  props: ["howManyComent"],

  components: {
    GuestbookCard
  },

  data: () => ({
    model: 2,
    lastGuestbookMessage: []
  }),

  mounted() {
    apiService.getAll("wp/v2/gold_book").then(data => {
      console.log(data)
      this.lastGuestbookMessage = data.slice(
        0,
        this.howManyComent
      );
    });
  }
};
</script>

<style lang="scss" scope>
.backgroundGreySlide {
  position: relative;
  background-color: var(--v-grey-base);
  height: 250px;
  margin: 100px 0 100px 0;
  .slideAbsolute {
    position: absolute;
    width: 100%;
    top: -37%;
  }
}
.v-slide-item--active {
  transform: scale(1.05);
}
.v-slide-group__prev,
.v-slide-group__next {
  width: 100px;
  span {
    width: 50px;
    height: 50px;
  }
  svg {
    width: 40px;
    height: 40px;
  }
}
</style>
