<template>
  <div class="mb-12">
    <AgendaCard
      v-for="date in dates"
      :key="date.id"
      v-bind:title="
        date.play ? date.play.title : date.event ? date.event.title : null
      "
      v-bind:text="
        date.play ? date.play.resum : date.event ? date.event.body : null
      "
      v-bind:date="date.start_date"
      v-bind:city="date.house ? 'Le Foyer' : date.city"
      v-bind:place="date.place"
      v-bind:imgUrl="
        `${apipath}${
          date.play
            ? date.play.photo.moyen
            : date.event
            ? date.event.photo.moyen
            : null
        }`
      "
      v-bind:thePlay="date.play ? date.play : date.event ? date.event : null"
      v-bind:type="date.event ? 'evenement' : 'spectacle'"
    />
  </div>
</template>

<script>
import AgendaCard from "../../../components/cards/agendaCard";
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "HouseAgenda",

  components: {
    AgendaCard
  },

  data: () => ({
    dates: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getAll("houses").then(data => {
      this.dates = data.calendars.calendars;
    });
  }
};
</script>
