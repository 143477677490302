import Vue from "vue";
import VueRouter from "vue-router";
import VuejsDialog from "vuejs-dialog";

import Home from "../views/Home.vue";

// import AgendaIndex from "../views/agenda";
import Agenda from "../views/agenda/agenda";
import Play from "../views/agenda/play";
import PlayItem from "../views/agenda/play/show.vue";

import News from "../views/news";
import ActuItem from "../views/news/show.vue";

import Festival from "../views/festivalTest";
import FestivalInfo from "../views/festivalTest/info";
import FestivalPlay from "../views/festivalTest/festivalPlay";
import FestivalProgram from "../views/festivalTest/program";
import Politique from "../views/festivalTest/politique";

import House from "../views/house";
import HouseAgenda from "../views/house/houseAgenda";
import HousePlace from "../views/house/place";
import HouseLibrary from "../views/house/library";
import HouseLibraryItem from "../views/house/library/show.vue";

import About from "../views/about";
// import aboutPlay from "../views/about/aboutPlay";
import Project from "../views/about/project";
import Staff from "../views/about/staff";
import StaffItem from "../views/about/staff/show.vue";

import Admin from "../views/00-admin";

// import Support from "../views/support"
// import Give from "../views/support/give"
import Guestbook from "../views/support/guestbook";

Vue.use(VueRouter);
Vue.use(VuejsDialog);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // HOME
    {
      path: "/accueil",
      alias: "/",
      component: Home,
      name: "Home"
    },
    // AGENDA
    {
      path: "/agenda",
      component: Agenda,
      name: "agenda"
    },
    {
      path: "/spectacles",
      component: Play,
      name: "play"
    },
    {
      path: "/spectacles/:slug&id=:id&:type",
      component: PlayItem,
      name: "playItem"
    },
    // ACTUALITIES
    {
      path: "/actualites",
      component: News,
      name: "actualities"
    },
    {
      path: "/actualites/:slug&id=:id",
      component: ActuItem,
      name: "actuItem"
    },
    // FESTIVAL
    {
      path: "/festival",
      name: "festival",
      component: Festival,
      redirect: { name: "festivalInfo" },
      children: [
        {
          path: "infos-pratique",
          name: "festivalInfo",
          component: FestivalInfo
        },
        {
          path: "affiche",
          name: "festivalPlay",
          component: FestivalPlay
        },
        {
          path: "programme",
          name: "festivalProgram",
          component: FestivalProgram
        }
      ]
    },
    {
      path: "/festival/politique-billetterie",
      component: Politique,
      name: "politique"
    },
    // FOYER
    {
      path: "/foyer",
      name: "house",
      component: House,
      redirect: { name: "housePlace" },
      children: [
        {
          path: "le-lieu",
          name: "housePlace",
          component: HousePlace
        },
        {
          path: "agenda",
          name: "houseAgenda",
          component: HouseAgenda
        },
        {
          path: "la-bibliotheque",
          name: "houseLibrary",
          component: HouseLibrary
        }
      ]
    },
    {
      path: "/foyer/la-bibliotheque/:slug&id=:id",
      component: HouseLibraryItem,
      name: "houseLibraryItem"
    },
    // ABOUT
    {
      path: "/a-propos",
      name: "about",
      component: About,
      redirect: { name: "project" },
      children: [
        {
          path: "projet",
          name: "project",
          component: Project
        },
        {
          path: "livre-d-or",
          name: "guestbook",
          component: Guestbook
        },
        {
          path: "la-troupe",
          name: "staff",
          component: Staff
        }
      ]
    },
    {
      path: "/a-propos/la-troupe/:slug&id=:id",
      component: StaffItem,
      name: "staffItem"
    },
    {
      path: "/page-cachee-upload-photo",
      component: Admin,
      name: "admin"
    }
    // SUPPORT
    // {
    //   path: "/soutenir",
    //   name: "support",
    //   component: Support,
    //   redirect: { name: "give" },
    //   children: [{
    //     path: 'dons',
    //     name: 'give',
    //     component: Give
    //   },
    //   {
    //     path: 'livre-d-or',
    //     name: 'guestbook',
    //     component: Guestbook,
    //   }]
    // },
  ]
});

export default router;
