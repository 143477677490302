var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"upOthers"},[_c('BackArrow'),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","lg":"9"}},[_c('v-img',{attrs:{"height":"400","src":_vm.play.acf.image}}),_c('h1',{staticClass:"text-h4 primary--text mt-8",domProps:{"innerHTML":_vm._s(_vm.play.title.rendered)}}),_c('h3',{staticClass:"text-h6 secondary--text mt-2",domProps:{"innerHTML":_vm._s(_vm.play.acf.author)}}),_c('v-row',{staticClass:"my-4"},[(_vm.play.acf.information)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("$peopleYellow")])],1):_vm._e(),(_vm.play.acf.information)?_c('v-col',{staticClass:"mr-6 primary--text font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.play.acf.information))]):_vm._e(),(_vm.play.acf.create_date)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("$dateYellow")])],1):_vm._e(),(_vm.play.acf.create_date)?_c('v-col',{staticClass:"mr-6 primary--text font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.play.acf.create_date))]):_vm._e(),(_vm.play.acf.duration)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("$clock")])],1):_vm._e(),(_vm.play.acf.duration)?_c('v-col',{staticClass:"mr-6 primary--text font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.play.acf.duration))]):_vm._e()],1),_c('p',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.play.content.rendered)}}),(
          this.$route.params.type === 'spectacle' &&
            _vm.play.acf.linked_real_play &&
            _vm.play.acf.note_dintention !== ''
        )?_c('v-row',{staticClass:"backgroundGrey my-10"},[_c('v-col',{staticClass:"d-flex align-center justify-center flex-column",attrs:{"cols":"3"}},[_c('v-avatar',{attrs:{"size":"80px"}},[(_vm.real.acf.image)?_c('v-img',{attrs:{"alt":`avatar - ${_vm.real.title.rendered}`,"src":_vm.real.acf.image}}):_c('span',{staticClass:"white--text text-h5",domProps:{"textContent":_vm._s(_vm.real.title.rendered.slice(0, 1))}})],1),_c('span',{staticClass:"text-subtitle-2 mt-2 text-center",domProps:{"textContent":_vm._s(_vm.real.title.rendered)}}),_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s('Metteur en scène')}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{staticClass:"coteMS left",attrs:{"cols":""}},[_c('span',{staticClass:"white--text"},[_vm._v("“")])]),_c('v-col',{attrs:{"cols":"9"}},[_c('p',{staticClass:"text-body-2",domProps:{"innerHTML":_vm._s(_vm.play.acf.note_dintention)}})]),_c('v-col',{staticClass:"coteMS right",attrs:{"cols":""}},[_c('span',{staticClass:"white--text"},[_vm._v("”")])])],1)],1)],1):_vm._e(),_c('div',{staticClass:"mb-10 mt-5"},[(
            this.$route.params.type === 'spectacle' &&
              _vm.play.acf.linked_play_roles
          )?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('h6',{staticClass:"primary--text text-subtitle-2",domProps:{"textContent":_vm._s('Metteur en scène')}})]),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(_vm.real.title.rendered)}})])],1):_vm._e(),(this.$route.params.type === 'spectacle')?_c('v-row',[(_vm.play.acf.linked_play_roles.length > 0)?_c('v-col',{attrs:{"cols":"4"}},[_c('h6',{staticClass:"primary--text text-subtitle-2",domProps:{"textContent":_vm._s('Comédiens')}})]):_vm._e(),_c('v-col',{attrs:{"cols":"8"}},_vm._l((_vm.play.acf.linked_play_roles),function(role){return _c('p',{key:role.id,staticClass:"text-body-2 mb-2",domProps:{"textContent":_vm._s(role.post_title)}})}),0)],1):_vm._e(),_vm._l((_vm.play.acf.technical_roles),function(trole){return _c('v-row',{key:trole.id},[_c('v-col',{attrs:{"cols":"4"}},[_c('h6',{staticClass:"primary--text text-subtitle-2",domProps:{"textContent":_vm._s(trole.role_name)}})]),_c('v-col',{attrs:{"cols":"8"}},[_c('p',{staticClass:"text-body-2",domProps:{"textContent":_vm._s(trole.name)}})])],1)})],2),(_vm.play.video !== '')?_c('div',{staticClass:"mb-10 mt-5"},[_c('v-responsive',{attrs:{"aspect-ratio":16 / 9}},[_c('iframe',{attrs:{"width":"100%","height":"100%","src":_vm.play.acf.lien_video,"title":_vm.play.title.rendered,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"3"}},[(_vm.dates.length > 0)?_c('div',{staticClass:"stickyCalendar"},[_c('DateCard',{attrs:{"calendars":_vm.dates}})],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }