<template>
  <div class="upOthers">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h4 primary--text" v-text="'Photos des membres'"></h3>
        </v-col>
        <v-col
          v-for="staff in StaffPhotos"
          :key="staff.id"
          lg="2"
          md="3"
          sm="4"
          cols="6"
        >
          <img
            v-bind:src="staff.photo ? `${apipath}${staff.photo.normal}` : false"
            style="height: 100px"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <h3
            class="text-h4 primary--text"
            v-text="'Photos des spectacles'"
          ></h3>
        </v-col>
        <v-col
          v-for="play in PlayPhotos"
          :key="play.id"
          lg="2"
          md="3"
          sm="4"
          cols="6"
        >
          <img
            v-bind:src="play.photo ? `${apipath}${play.photo.normal}` : false"
            style="height: 100px"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <h3
            class="text-h4 primary--text"
            v-text="'Photos des Actualités'"
          ></h3>
        </v-col>
        <v-col
          v-for="actu in ActuPhotos"
          :key="actu.id"
          lg="2"
          md="3"
          sm="4"
          cols="6"
        >
          <img
            v-bind:src="actu.photo ? `${apipath}${actu.photo.normal}` : false"
            style="height: 100px"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import TitleAppBar from "../../components/titleAppBar"
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "Admin",

  components: {},

  data: () => ({
    StaffPhotos: [],
    PlayPhotos: [],
    ActuPhotos: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getAll("staffs").then(data => {
      this.StaffPhotos = data;
    });
    apiService.getAll("plays").then(data => {
      this.PlayPhotos = data.plays.plays;
    });
    apiService.getAll("actualites").then(data => {
      console.log(data.actualities);
      this.ActuPhotos = data.actualities.actualities;
    });
  }
};
</script>
