<template>
  <v-card elevation="0" style="background-color:transparent;">
    <a
      :href="
        $router.resolve({
          name: 'playItem',
          params: { slug: thePlay.slug, id: thePlay.id, type: 'spectacle' }
        }).href
      "
    >
      <v-img
        height="230"
        :src="thePlay.acf.image"
      ></v-img>

      <h3
        class="text-h5 primary--text font-weight-bold my-3"
        v-html="thePlay.title.rendered"
      ></h3>

      <v-chip
        v-if="thePlay.acf.information !== ''"
        label
        outlined
        small
        style="border-radius:0 !important"
        >{{ thePlay.acf.information }}</v-chip
      >

      <p class="text-body-2 mt-2 textcolor black--text">
        <span
          v-html="
            `${thePlay.content.rendered.slice(0, 200) + '...'}
        <a 
          href='
            ${
              $router.resolve({
                name: 'playItem',
                params: { slug: thePlay.slug, id: thePlay.id, type: 'spectacle' }
              }).href
            }
          '
          class='blue--text font-weight-medium font-italic'
        >lire la suite</a>`
          "
        ></span>
      </p>
    </a>
  </v-card>
</template>

<script>
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "PlayCardFestival",
  props: ["playId"],

  data: () => ({
    thePlay: [],
  }),

  mounted() {
    apiService.getById("wp/v2/plays", this.playId).then(data => {
      this.thePlay = data
    });
  }
};
</script>

<style lang="scss" scope>
.v-chip {
  border-color: #0b3753 !important;
  color: #0b3753 !important;
}
</style>
