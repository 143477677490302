<template>
  <v-app class="overflow-hidden">
    <AppBar />

    <v-main id="scrolling_screen">
      <router-view></router-view>
    </v-main>

    <div
      class="parallaxeForm1"
      v-bind:style="{
        backgroundImage: `url(${require('@/assets/icos/Ellipse-moyenne.png')})`
      }"
      v-rellax="{ speed: 4.5 }"
    ></div>
    <div
      class="parallaxeForm2"
      v-bind:style="{
        backgroundImage: `url(${require('@/assets/icos/big-heart.png')})`
      }"
      v-rellax="{ speed: 4.5 }"
    ></div>

    <Footer />
  </v-app>
</template>

<script>
import AppBar from "./components/appBar";
import Footer from "./components/footer";

export default {
  name: "App",

  components: {
    Footer,
    AppBar
  },

  data: () => ({
    image: "./assets/icos/Ellipse-petite.png"
  }),

  methods: {
    thePadding() {
      if (window.innerWidth < 960) {
        return 90;
      } else {
        return 150;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#scrolling_screen {
  position: relative;
}
.parallaxeForm1 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 5%;
  top: 20%;
  z-index: 3;
}
.parallaxeForm2 {
  position: absolute;
  width: 600px;
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  left: -5%;
  top: 28%;
  z-index: 3;
}
</style>

<style lang="scss">
.upOthers {
  position: relative;
  z-index: 2000;
}
// CHANGE DEFAULT FONT
@font-face {
  font-family: "farray";
  src: url(./assets/fonts/farray.regular-webfont.woff2) format("woff2"),
    url(./assets/fonts/farray.regular-webfont.woff) format("woff");
}
.v-application .text-h1,
.v-application .text-h2 {
  font-family: "timberline" !important;
  line-height: 5rem;
}
.v-application .text-h3 {
  font-family: "farray" !important;
  font-size: 4rem !important;
}
.v-application .text-h4 {
  font-family: "farray" !important;
}
.v-application .text-h5 {
  font-family: "farray" !important;
}
.v-application .text-h6,
.v-application .text-subtitle-1,
.v-application .text-subtitle-2,
.v-application .text-body-1,
.v-application .text-body-2,
.v-application .text-caption,
.v-btn {
  font-family: "Montserrat" !important;
}

a {
  text-decoration: none;
}

.chipPerso {
  border-radius: 0 !important;
}
</style>
