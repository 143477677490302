<template>
  <v-container class="upOthers mt-5">
    <v-row no-gutters>
      <v-col cols="12" sm="10" md="9" lg="8" class="ma-auto">
        <BackArrow />
        <v-img height="400" :src="actuality.acf.image"></v-img>
        <h1
          class="text-h4 primary--text mt-8"
          v-text="actuality.title.rendered"
        ></h1>
        <p
          class="text-body-1 mt-6 mb-12"
          v-html="actuality.content.rendered"
        ></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackArrow from "../../components/backArrow";
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "ActuItem",

  components: {
    BackArrow
  },

  data: () => ({
    actuality: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getById("wp/v2/posts", this.$route.params.id).then(data => {
      this.actuality = data;
    });
    // apiService.getById("actualites", this.$route.params.id).then(data => {
    //   this.actuality = data.actuality.actuality;
    // });
  }
};
</script>
