<template>
  <v-row>
    <v-col cols="12" sm="10" lg="8">
      <v-img width="100%" contain :src="house.acf.image"></v-img>

      <h3 class="text-h5 mt-10 mb-5 primary--text">C'est quoi ?</h3>
      <p class="text-body-2 black--text" v-html="house.acf.body"></p>
      <h4 class="text-h5 mt-10 primary--text">Venir au foyer</h4>
      <v-list>
        <v-list-item v-for="c in contact" :key="c.icon" class="pl-0">
          <v-list-item-icon>
            <v-icon large> ${{ c.icon }} </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-body-2 mt-3">{{
              c.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "Place",

  data: () => ({
    contact: [
      { icon: "positionYellow", text: "" },
      {
        icon: "mailYellow",
        text: ""
      }
    ],
    house: {}
  }),

  mounted() {
    apiService.getAll("acf/v3/options/options").then(data => {
      this.house = data;
      this.contact[0].text = data.acf.address;
      this.contact[1].text = data.acf.email;
    });
  }
};
</script>
