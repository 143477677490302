<template>
  <v-card elevation="0" class="chipPerso ml-10 white--text" color="primary">
    <v-carousel
      hide-delimiters
      height="auto"
      next-icon="$nextArrowIconWhite"
      prev-icon="$prevArrowIconWhite"
      mandatory
    >
      <v-carousel-item
        v-for="c in calendars"
        :key="c.id"
        :active-class="`active-date`"
      >
        <div class="text-center pa-4" style="width:100%">
          <p
            v-text="dateFormat(c.acf.date, 'dddd')"
            class="ma-0 text-body-2 white--text"
          ></p>
          <p
            v-text="dateFormat(c.acf.date, 'DD')"
            class="mx-0 my-5 text-h3 white--text"
          ></p>
          <p
            v-text="dateFormat(c.acf.date, 'MMM YY')"
            class="mx-0 mb-1 text-subtitle-1 font-weight-medium text-uppercase white--text"
          ></p>
        </div>

        <div class="borderTop pa-6">
          <v-row no-gutters v-for="p in c.acf.ajouter_des_prix" :key="p.id">
            <v-col cols="9">
              <p
                class="text-uppercase text-subtitle-2 white--text font-weight-bold mb-0"
                v-text="p.price_name"
              ></p>
            </v-col>
            <v-col cols="3" class="text-right">
              <span class="font-weight-bold white--text">{{ p.price }}€</span>
            </v-col>
          </v-row>

          <div class="mt-2">
            <span
              class="white--text text-caption text-uppercase"
              v-text="dateFormat(c.acf.date, 'HH[H]mm')"
            ></span>
          </div>
          <div>
            <span
              class="white--text text-caption"
              v-if="c.acf.choose_place === 'home'"
              >Le Foyer, allée du comissaire Félix Landry 24100 Bergerac</span
            >
            <span class="white--text text-caption" v-else
              >{{ c.city }}{{ c.place ? ", " + c.place : null }}</span
            >
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>

    <div class="borderTop">
      <v-btn
        class="pa-6 font-weight-bold primary"
        plain
        block
        style="border-radius:0;"
        disabled
      >
        Réserver
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "DateCard",
  props: ["calendars"],

  methods: {
    dateFormat(data, format) {
      return moment(data, "DD/MM/YYYY HH:mm")
        .utc()
        .locale("fr")
        .format(format);
    }
  }
};
</script>

<style lang="scss" scope>
.borderTop {
  border-top: 1px solid white;
}
.chipPerso .v-window__prev {
  background: transparent !important;
  top: 70px;
}
.chipPerso .v-window__next {
  background: transparent !important;
  top: 70px;
}
</style>
