<template>
  <v-card elevation="0" style="background-color:transparent;" class="mb-6">
    <v-row>
      <v-col cols="4" sm="2" class="text-center">
        <p v-text="dateFormat(date, 'dddd')" class="ma-0 text-caption"></p>
        <p
          v-text="dateFormat(date, 'DD')"
          class="mx-0 my-3 text-h3 black--text"
        ></p>
        <p
          v-text="dateFormat(date, 'MMM')"
          class="mx-0 mb-0 text-subtitle-1 font-weight-medium"
        ></p>
        <p
          v-text="dateFormat(date, 'H[h]mm')"
          class="mx-0 mb-1 text-subtitle-2"
        ></p>
        <p v-text="city" class="ma-0 text-caption"></p>
        <p v-text="place" class="ma-0 text-caption"></p>
      </v-col>
      <v-col cols="4" md="4" lg="3" class="ma-0 d-none d-sm-block">
        <v-img height="200" :src="thePlay.acf.image"></v-img>
      </v-col>
      <v-col cols="8" sm="6" md="4">
        <a
          :href="
            $router.resolve({
              name: 'playItem',
              params: { slug: thePlay.slug, id: thePlay.id, type: type }
            }).href
          "
        >
          <h3 class="text-h5 hoverTitle font-weight-bold" v-text="title"></h3>
        </a>
        <p class="text-body-2 mt-5 black--text">
          <span
            v-html="
              `${thePlay.content.rendered.slice(0, 200)}
          <a 
            href='
              ${
                $router.resolve({
                  name: 'playItem',
                  params: { slug: thePlay.slug, id: thePlay.id, type: type }
                }).href
              }
            '
            class='blue--text font-weight-medium font-italic'
          >lire la suite</a>`
            "
          ></span>
        </p>
      </v-col>
      <v-spacer class="d-none d-md-block"></v-spacer>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "GuestbookCard",
  props: [
    "text",
    "title",
    "date",
    "city",
    "place",
    "postType",
    "eventId",
    "type"
  ],

  data: () => ({
    thePlay: {}
  }),

  mounted() {
    apiService
      .getById(`wp/v2/${this.postType}`, this.eventId)
      .then(response => {
        this.thePlay = response;
      });
  },

  methods: {
    dateFormat(data, format) {
      return (
        moment(data, "DD/MM/YYYY HH:mm")
          // .utc()
          .locale("fr")
          .format(format)
      );
    }
  }
};
</script>

<style lang="scss" scope>
.hoverTitle {
  color: var(--v-primary-base) !important;
}
.hoverTitle:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0) !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--v-primary-base);
}
</style>
