<template>
  <a
    :href="
      $router.resolve({
        name: 'staffItem',
        params: { slug: slug, id: id }
      }).href
    "
    class="d-block text-center staffHover"
  >
    <v-avatar size="150px" color="primary">
      <v-img v-if="imgUrl" :src="imgUrl" :alt="name"></v-img>
      <span
        v-else
        class="white--text text-h5 text-uppercase"
        v-text="name.slice(0, 1)"
      ></span>
    </v-avatar>
    <h5
      class="text-subtitle-2 black--text text-uppercase text-center mx-2 mt-3"
      v-text="name"
    ></h5>
  </a>
</template>

<script>
export default {
  name: "ActuCard",
  props: ["imgUrl", "name", "slug", "id"]
};
</script>

<style lang="scss" scoped>
.staffHover .v-avatar {
  transition: all 0.2s;
}
.staffHover:hover .v-avatar {
  border: 4px solid var(--v-primary-base);
}
</style>
