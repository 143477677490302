var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"background-color":"transparent"},attrs:{"elevation":"0"}},[_c('a',{attrs:{"href":_vm.$router.resolve({
        name: 'playItem',
        params: { slug: _vm.slug, id: _vm.id, type: 'spectacle' }
      }).href}},[_c('v-img',{attrs:{"height":"230","src":_vm.imgUrl}}),_c('h3',{staticClass:"text-h5 primary--text font-weight-bold my-3",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.information !== '')?_c('v-chip',{staticStyle:{"border-radius":"0 !important"},attrs:{"label":"","outlined":"","small":""}},[_vm._v(_vm._s(_vm.information))]):_vm._e(),_c('p',{staticClass:"text-body-2 mt-2 textcolor black--text"},[_c('span',{domProps:{"innerHTML":_vm._s(
          `${_vm.text.slice(0, 200) + '...'}
      <a 
        href='
          ${
            _vm.$router.resolve({
              name: 'playItem',
              params: { slug: _vm.slug, id: _vm.id, type: 'spectacle' }
            }).href
          }
        '
        class='blue--text font-weight-medium font-italic'
      >lire la suite</a>`
        )}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }