<template>
  <v-container class="upOthers">
    <BackArrow />

    <v-row no-gutters>
      <v-col cols="12" sm="8" lg="9">
        <v-img height="300px" width="300px" :src="podcast.acf.image"></v-img>
        <h1
          class="text-h4 primary--text mt-8"
          v-text="podcast.title.rendered"
        ></h1>
        <p class="text-body-2" v-html="podcast.content.rendered"></p>
        <div class="my-10" v-if="podcast.url" v-html="podcast.url"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIService } from "../../../APIService";
const apiService = new APIService();
import BackArrow from "../../../components/backArrow";

export default {
  name: "LibraryItem",

  components: {
    BackArrow
  },

  data: () => ({
    podcast: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getById("wp/v2/library", this.$route.params.id).then(data => {
      this.podcast = data;
    });
  }
};
</script>
