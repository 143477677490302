<template>
  <v-row>
    <v-col cols="12" md="10" lg="8">
      <v-row>
        <v-col v-for="play in festivalPlay" :key="play.id" cols="12" sm="6">
          <PlayCardFestival
            v-bind:playId="play.play"
          />
        </v-col>
      </v-row>

      <v-row v-if="billetweb" class="mb-15">
        <v-col cols="3">
          <v-btn
            block
            rounded
            class="secondary mt-2"
            elevation="2"
            x-large
            :href="billetweblink"
            target="_blank"
          >réserver</v-btn>
        </v-col>
        <v-col cols="5">
          <v-btn
            text
            color="primary"
            :to="{ name: 'politique' }"
            class="pt-12"
          >
            politique de billetterie
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer cols="0" md="2" lg="4"></v-spacer>
  </v-row>
</template>

<script>
import PlayCardFestival from "../../../components/cards/playCardFestival.vue";
import { APIService } from "../../../APIService";
const apiService = new APIService();
// import moment from "moment";

export default {
  name: "FestivalPlay",

  components: {
    PlayCardFestival
  },

  data: () => ({
    festivalPlay: [],
    billetweb: false,
    billetweblink: ""
  }),

  mounted() {
    apiService.getAll("wp/v2/festival").then(data => {
      this.festivalPlay = data[0].acf.programme
    }); 

    apiService.getAll("acf/v3/options/options?slug=informations").then(data => {
      this.billetweb = data.acf.activate_billeterie,
      this.billetweblink = data.acf.link_billetterie
    });
  }
};
</script>
