<template>
  <v-app-bar
    :class="
      `AppBarTransition ${
        this.navigations[1].items[0].little ? 'pt-1' : 'pt-2'
      }`
    "
    color="white"
    :style="`transition: all 0.3s;`"
    :height="this.navigations[1].items[0].little ? 62 : 110"
    shrink-on-scroll
    scroll-target="#scrolling_screen"
  >
    <!-- MOBILE PART -->
    <v-container fluid class="d-flex d-md-none px-0 pb-0 pt-1">
      <div>
        <a
          :href="
            $router.resolve({
              name: 'Home'
            }).href
          "
        >
          <v-img
            class="ImgTransition"
            contain
            :height="this.navigations[1].items[0].little ? 45 : 80"
            :width="this.navigations[1].items[0].little ? 75 : 120"
            :src="require('../../assets/logos/TROC-colors.png')"
            alt="logo TROC couleur"
          ></v-img>
        </a>
      </div>
      <div class="flex-grow-1 d-flex justify-end align-center">
        <div
          v-for="(navSm, index) in navigationsSmAndDown"
          :key="index"
          class="ml-5"
        >
          <v-btn
            v-if="navSm.type === 'button'"
            plain
            rounded
            class="primary d-none d-sm-block"
            elevation="2"
            small
            :href="navSm.href"
            target="_blank"
            >{{ navSm.text }}</v-btn
          >
          <v-menu
            v-else-if="navSm.type === 'mobileNav'"
            open-on-hover
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                v-ripple="false"
                block
                plain
                raised
                class="white mt-1 mr-15"
                v-bind="attrs"
                v-on="on"
                >{{ navSm.text }}</v-btn
              >
            </template>

            <v-list>
              <v-list-item-group color="grey">
                <v-list-item
                  v-for="(subnav, index) in navSm.subnav"
                  :key="index"
                  :to="{ name: subnav.href }"
                  active-class="blackColor"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ subnav.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-container>
    <!-- END MOBILE PART -->

    <v-container fluid class="py-0 px-0 d-none d-md-block text-center">
      <v-btn-toggle tile group active-class="nav-active">
        <v-row no-gutters>
          <v-col
            v-for="group in navigations"
            :key="group.group"
            :cols="group.cols"
            align-self="center"
          >
            <v-row no-gutters :justify="group.justify">
              <v-col
                v-for="(nav, index) in group.items"
                :key="index"
                cols="auto"
              >
                <v-btn
                  v-if="nav.type === 'simpleNav'"
                  v-ripple="false"
                  depressed
                  block
                  plain
                  raised
                  tile
                  class="white mx-0 mx-md-2 mx-xl-4 px-0 px-lg-2"
                  :small="$vuetify.breakpoint.mdOnly"
                  :to="{ name: nav.href }"
                  >{{ nav.text }}</v-btn
                >

                <v-menu
                  v-else-if="nav.type === 'dropdown'"
                  open-on-hover
                  bottom
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      v-ripple="false"
                      block
                      plain
                      raised
                      tile
                      class="white mx-0 mx-md-2 mx-xl-4 px-0 px-lg-2"
                      :small="$vuetify.breakpoint.mdOnly"
                      v-bind="attrs"
                      v-on="on"
                      :to="{ name: nav.href }"
                      >{{ nav.text }}</v-btn
                    >
                  </template>

                  <v-list dense>
                    <v-list-item-group color="grey">
                      <v-list-item
                        v-for="(subnav, index) in nav.subnav"
                        :key="index"
                        :to="{ name: subnav.href }"
                        active-class="blackColor"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{
                            subnav.text
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

                <a
                  v-else-if="nav.type === 'logo'"
                  :href="
                    $router.resolve({
                      name: 'Home'
                    }).href
                  "
                >
                  <v-img
                    class="ImgTransition"
                    :height="nav.little ? 45 : 90"
                    contain
                    :src="require('../../assets/logos/TROC-colors.png')"
                    alt="logo TROC couleur"
                  ></v-img>
                </a>

                <v-btn
                  v-else-if="nav.type === 'button' && nav.activate === true"
                  plain
                  rounded
                  :class="nav.colorBlue ? 'secondary' : 'primary'"
                  elevation="2"
                  :small="$vuetify.breakpoint.mdAndDown"
                  :href="nav.href"
                  target="_blank"
                  >{{ nav.text }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-btn-toggle>
    </v-container>
  </v-app-bar>
</template>

<script>
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "AppBar",

  data: () => ({
    collapseOnScroll: true,
    navigations: [
      {
        group: 1,
        cols: "5",
        justify: "end",
        items: [
          {
            type: 'simpleNav',
            subnav: [],
            text: 'agenda',
            href: 'agenda'
          },
          {
            type: "simpleNav",
            subnav: [],
            text: "spectacles",
            href: "play"
          },
          {
            type: "simpleNav",
            subnav: [],
            text: "actualités",
            href: "actualities"
          },
          {
            type: "dropdown",
            subnav: [
              { text: "Infos pratiques", href: "festivalInfo" },
              { text: "A l'affiche", href: "festivalPlay" },
              { text: "Programme", href: "festivalProgram" }
            ],
            text: "le festival",
            href: "festival"
          }
        ]
      },
      {
        group: 2,
        cols: "2",
        justify: "center",
        items: [
          {
            type: "logo",
            little: false,
            href: "Home"
          }
        ]
      },
      {
        group: 3,
        cols: "3",
        justify: "start",
        items: [
          {
            type: "dropdown",
            subnav: [
              { text: "le lieu", href: "housePlace" },
              { text: "la Bibliothèque", href: "houseLibrary" }
            ],
            text: "le foyer",
            href: "house"
          },
          {
            type: "dropdown",
            subnav: [
              { text: "Le projet", href: "project" },
              { text: "Livre d'or", href: "guestbook" },
              { text: "La troupe", href: "staff" }
            ],
            text: "à propos",
            href: "project"
          }
        ]
      },
      {
        group: 4,
        cols: "2",
        justify: "start",
        items: [
          {
            type: "button",
            text: "Nous soutenir",
            href: "https://www.helloasso.com/associations/theatre-du-roi-de-coeur/formulaires/5",
            activate: true
          },
          {
            type: "button",
            text: "réserver",
            href: 'https://www.billetweb.fr/pro/billetterie-festival-troc',
            activate: false,
            colorBlue: true
          }
        ]
      },
    ],

    navigationsSmAndDown: [
      {
        type: "button",
        text: "réserver",
        href: 'https://www.billetweb.fr/pro/billetterie-festival-troc',
        activate: false,
        colorBlue: true
      },
      {
        type: "button",
        text: "Nous soutenir",
        href: "https://www.helloasso.com/associations/theatre-du-roi-de-coeur/formulaires/3/widget"
      },
      {
        type: "mobileNav",
        subnav: [
          {
            text: "accueil",
            href: "Home"
          },
          {
            text: "agenda",
            href: "agenda"
          },
          {
            text: "actualités",
            href: "actualities"
          },
          {
            text: "le festival",
            href: "festival"
          },
          {
            text: "le foyer",
            href: "house"
          },
          {
            text: "à propos",
            href: "project"
          }
        ],
        text: "menu"
      }
    ]
  }),

  mounted() {
    apiService.getAll("acf/v3/options/options?slug=informations").then(data => {
      if (data.acf.activate_billeterie) {
        this.navigations[3].items[0].activate = false
        this.navigations[3].items[1].activate = true
        this.navigationsSmAndDown[1].activate = true
      }
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.navigations[1].items[0].little = true;
      } else {
        this.navigations[1].items[0].little = false;
      }
    }
  }
};
</script>

<style lang="scss" scope>
.AppBarTransition {
  position: sticky !important;
  top: 0;
  z-index: 10000;
}
.ImgTransition {
  transition: all 0.3s;
}
.nav-active::before {
  display: block;
  content: " ";
  background-image: url("../../assets/icos/barre_menu-jaune.png");
  background-position: center;
  background-size: 20px 100%;
}
.v-btn:hover::before,
.v-btn::after,
.v-btn:focus::before,
.v-btn::before {
  background-color: transparent !important;
}
.blackColor.v-list-item--active {
  color: black !important;
}
</style>
