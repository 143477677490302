var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pr-4 mb-10",staticStyle:{"background-color":"transparent","border-radius":"0","border-right":"1px solid black"},attrs:{"elevation":"0"}},[_c('p',{staticClass:"mx-0 mb-0 text-h4 font-weight-medium",domProps:{"textContent":_vm._s(_vm.dateFormat(_vm.date, 'HH:mm'))}}),_c('p',{staticClass:"mx-0 mt-3 mb-0 text-subtitle-2 black--text",domProps:{"textContent":_vm._s(_vm.play.post_title)}}),_c('p',{staticClass:"mb-1 text-caption",domProps:{"textContent":_vm._s(_vm.place)}}),(_vm.information !== '')?_c('v-chip',{staticClass:"mb-2",staticStyle:{"border-radius":"0 !important"},attrs:{"label":"","outlined":"","x-small":""}},[_vm._v(_vm._s(_vm.information))]):_vm._e(),_c('v-img',{attrs:{"height":"120","src":_vm.theImg}}),_c('p',{staticClass:"text-caption mt-2 black--text"},[_c('span',{domProps:{"innerHTML":_vm._s(
        `${_vm.play.post_content.slice(0, 150) + '...'}
    <a 
      href='
        ${
          _vm.$router.resolve({
            name: 'playItem',
            params: { slug: _vm.play.post_name, id: _vm.play.ID, type: 'spectacle' }
          }).href
        }
      '
      class='blue--text font-weight-medium font-italic'
    >lire la suite</a>`
      )}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }