import Vue from "vue";
import Vuetify from "vuetify/lib";
import Mail from "../assets/icos/MailIcon";
import Phone from "../assets/icos/PhoneIcon";
import Position from "../assets/icos/PositionIcon";
import Insta from "../assets/icos/InstaIcon";
import Facebook from "../assets/icos/FacebookIcon";

import PrevArrow from "../assets/icos/PrevArrowIcon";
import NextArrow from "../assets/icos/NextArrowIcon";
import ArrowBackWhite from "../assets/icos/ArrowBackWhite";
import ArrowNextWhite from "../assets/icos/ArrowNextWhite";
import ArrowBack from "../assets/icos/ArrowBack";
import ArrowNext from "../assets/icos/ArrowNext";

import YellowMail from "../assets/icos/YellowMail";
import YellowPhone from "../assets/icos/YellowPhone";
// import YellowPosition from "../assets/icos/Position"
import YellowHome from "../assets/icos/YellowHome";
import Clock from "../assets/icos/Clock";
import YellowPeople from "../assets/icos/YellowPeople";
import YellowDate from "../assets/icos/YellowDate";
import YellowParking from "../assets/icos/YellowParking";
import YellowPosition from "../assets/icos/YellowPosition";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#FAB722",
        secondary: "#0C3959",
        grey: "#F5F5F5",
        dark: "#000000",
        textColor: "#000000",
        blue: "#0B3753"
      }
    }
  },
  icons: {
    values: {
      mailIcon: {
        component: Mail
      },
      phoneIcon: {
        component: Phone
      },
      positionIcon: {
        component: Position
      },
      mailYellow: {
        component: YellowMail
      },
      phoneYellow: {
        component: YellowPhone
      },
      positionYellow: {
        component: YellowPosition
      },
      dateYellow: {
        component: YellowDate
      },
      peopleYellow: {
        component: YellowPeople
      },
      homeYellow: {
        component: YellowHome
      },
      parkingYellow: {
        component: YellowParking
      },
      instaIcon: {
        component: Insta
      },
      clock: {
        component: Clock
      },
      arrowBack: {
        component: ArrowBack
      },
      arrowNext: {
        component: ArrowNext
      },
      facebookIcon: {
        component: Facebook
      },
      prevArrowIcon: {
        component: PrevArrow
      },
      nextArrowIcon: {
        component: NextArrow
      },
      prevArrowIconWhite: {
        component: ArrowBackWhite
      },
      nextArrowIconWhite: {
        component: ArrowNextWhite
      }
    }
  }
});

export default vuetify;
