<template>
  <v-card
    elevation="0"
    style="background-color:transparent;position:relative;z-index:100;"
  >
    <a
      :href="
        $router.resolve({
          name: 'actuItem',
          params: { slug: slug, id: id }
        }).href
      "
    >
      <div>
        <v-img height="250" :src="imgUrl"></v-img>
      </div>
      <h6 class="text-h5 text-center black--text mt-7" v-text="title"></h6>
    </a>
  </v-card>
</template>

<script>
export default {
  name: "ActuFestivalCard",
  props: ["title", "imgUrl", "slug", "id"]
};
</script>
