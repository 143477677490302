import axios from "axios";
// const API_URL = process.env.VUE_APP_API_ROOT;
// const Api = axios.create({
//   baseURL: "https://api.theatreduroidecoeur.fr//wp-json/",
//   headers: {
//       'content-type': 'application/json',
//       'X-WP-Nonce': mynamespace.nonce
//   }
// });

export class APIService {
  
  APIurl() {
    if (process.env.VUE_APP_API_ROOT !== "http://localhost:3000") {
      return "";
    } else {
      return "https://apitroc-1y2amnbyj0.live-website.com/wp-json/";
    }
  }

  getAll(name) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}?per_page=100`;
    return axios
      .get(url)
      .then(response => response.data)
      .catch(error => console.log(error));
  }
  getAllPagination(name, page) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}?page=${page}/`;
    return axios
      .get(url)
      .then(response => response.data)
      .catch(error => console.log(error));
  }
  getById(name, id) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}/${id}`;
    return axios
      .get(url)
      .then(response => response.data)
      .catch(error => console.log(error));
  }
  create(name, form) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}`;
    // barear visiteurs 
    return axios.post(url, form).then(response => console.log(response)).catch(error => console.log(error));
  }
  update(name, id, data) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}/${id}`;
    return axios.put(url, data).catch(error => console.log(error));
  }
  destroy(name, id) {
    const url = `https://apitroc-1y2amnbyj0.live-website.com/wp-json/${name}/${id}`;
    return axios.delete(url).catch(error => console.log(error));
  }
}
