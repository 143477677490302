<template>
  <v-container class="mb-12">
    <TitleAppBar v-bind:title="'Agenda'" />
    <AgendaCard
      v-for="date in dates"
      :key="date.id"
      v-bind:title="date.title.rendered"
      v-bind:date="date.acf.date"
      v-bind:city="date.acf.choose_place"
      v-bind:place="date.acf.choose_place"
      v-bind:postType="date.acf.event.post_type"
      v-bind:eventId="date.acf.event.ID"
      v-bind:type="
        date.acf.event.post_type === 'event' ? 'evenement' : 'spectacle'
      "
    />
  </v-container>
</template>

<script>
import moment from "moment";
import AgendaCard from "../../../components/cards/agendaCard";
import { APIService } from "../../../APIService";
import TitleAppBar from "../../../components/titleAppBar";
const apiService = new APIService();

export default {
  name: "Agenda",

  components: {
    AgendaCard,
    TitleAppBar
  },

  data: () => ({
    dates: []
  }),

  mounted() {
    apiService.getAll("wp/v2/calendar").then(data => {

      let sortDataArray = data.sort((a, b) => {
        return moment(a.acf.date, 'DD/MM/YYYY').diff(moment(b.acf.date, 'DD/MM/YYYY'));
      })

      sortDataArray.forEach(element => {
        console.log(moment(element.acf.date, "DD/MM/YYYY"))
        if (moment(element.acf.date, "DD/MM/YYYY").isAfter(moment())) {
          this.dates.push(element)
        }
      });
      
    });
  }
};
</script>
