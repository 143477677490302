<template>
  <div
    style="position:relative;"
    :class="
      `d-flex align-center ${alignR == false ? 'flex-row' : 'flex-row-reverse'}`
    "
  >
    <div class="d-none d-sm-block" style="width:calc(50% - 20px)">
      <div
        :class="`bg_image ${alignR == false ? 'mr-12' : 'ml-12'}`"
        :style="`background-image:url(${imgUrl});`"
      ></div>
    </div>
    <div
      :style="
        `width:${$vuetify.breakpoint.smAndDown ? '100%' : 'calc(50% + 20px)'};`
      "
      :class="
        `d-flex ${
          alignR == false || $vuetify.breakpoint.smAndDown
            ? 'flex-row'
            : 'flex-row-reverse'
        }`
      "
    >
      <div style="width:40px">
        <div class="yellow_round mx-auto" ref="height"></div>
      </div>
      <div style="width:calc(100% - 40px)">
        <div
          :class="
            `${
              alignR == false || $vuetify.breakpoint.smAndDown
                ? 'ml-12 text-left'
                : 'mr-12 text-right'
            }`
          "
        >
          <a
            :href="
              $router.resolve({
                name: 'actuItem',
                params: { slug: slug, id: id }
              }).href
            "
          >
            <h3 class="text-h5 hoverTitle font-weight-bold" v-text="title"></h3>
          </a>
          <p class="text-body-2 mt-5 textcolor">
            <span
              v-html="
                `${returnText(text)}
            <a 
              href='
                ${
                  $router.resolve({
                    name: 'actuItem',
                    params: { slug: slug, id: id }
                  }).href
                }
              '
              class='blue--text font-weight-medium font-italic'
            >lire la suite</a>`
              "
            ></span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="index === 0" class="hackTheSystem" :style="heightStyle"></div>
  </div>
</template>

<script>
export default {
  name: "ActuCard",
  props: [
    "imgUrl",
    "alignR",
    "slug",
    "id",
    "text",
    "title",
    "thePosition",
    "index"
  ],

  data: () => ({
    heightStyle: ""
  }),

  methods: {
    returnText(text) {
      var cutlink = text.indexOf("<a class");
      return text.slice(0, cutlink);
    },

    matchHeight() {
      var heightString = this.$refs.height.offsetTop + "px";
      this.heightStyle = "height:" + heightString;
    }
  },

  mounted() {
    this.matchHeight();
  }
};
</script>

<style lang="scss" scoped>
.hackTheSystem {
  position: absolute;
  z-index: 2;
  left: calc(50% - 0.5px);
  top: 0;
  width: 1px;
  background-color: white;
  height: 100px;
}
.bg_image {
  height: 350px;
  background-size: cover;
  background-position: center;
}
.spacer {
  width: 1%;
}
.yellow_round {
  width: 12px;
  height: 12px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: var(--v-primary-base);
  position: relative;
  z-index: 100;
}
</style>
