<template>
  <v-container>
    <v-row class="mb-5">
      <v-col cols="12" sm="10" lg="8">
        <v-img width="100%" contain :src="organisation.image"></v-img>

        <h3 class="text-h5 mt-10 mb-5 primary--text">Le projet</h3>
        <p class="text-body-2 black--text" v-html="organisation.body"></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "Project",

  data: () => ({
    organisation: [],
    apipath: apiService.APIurl()
  }),

  mounted() {
    apiService.getAll("acf/v3/options/options").then(data => {
      console.log(data);
      this.organisation = data.acf.informations_groupe;
    });
  }
};
</script>
