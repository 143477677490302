var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgroundGreySlide"},[_c('div',{staticClass:"slideAbsolute"},[_c('v-slide-group',{staticClass:"slideWidth my-8 mx-auto",staticStyle:{"width":"1154px"},style:(`width:${
          _vm.$vuetify.breakpoint.xs
            ? 404
            : _vm.$vuetify.breakpoint.mdAndDown
            ? 1004
            : 1154
        }px`),attrs:{"center-active":"","show-arrows":"","next-icon":"$nextArrowIcon","prev-icon":"$prevArrowIcon"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.lastGuestbookMessage),function(message){return _c('v-slide-item',{key:message.id,style:(`width:${_vm.$vuetify.breakpoint.mdAndDown ? 300 : 350}px`),scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('div',{staticClass:"pa-5"},[_c('GuestbookCard',{attrs:{"pseudo":message.title.rendered,"body":message.acf.text,"city":message.acf.city,"zip":message.acf.zip_code,"date":message.date,"toggle":toggle,"active":active}})],1)]}}],null,true)})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }