<template>
  <v-row no-gutters>
    <v-col cols="12" sm="10" lg="8">
      <h2 class="text-h5 primary--text mb-8 text-uppercase">Concept</h2>
      <p class="text-body-2 mb-12">
        Les épisodes du Petit Prince ont été confectionnés en décembre 2021.
        Dans le secret de notre théâtre privé de ses spectateurs, nous l'avons
        pensé comme un cadeau pour se retrouver autrement, donner à entendre
        puisque nous ne pouvions plus vous donner à voir."
        <br /><br />
        à votre tour de participer à notre bibliothèque de récits qui ne demande
        qu'à être remplie de vos belles histoires. Pour cela :
        <br /><br />
        1. Choisissez votre sujet, fiction ou réalité, et laissez-vous aller à
        la rêverie.<br />
        2. Enregistrez, photographiez et/ou dessinez votre histoire. Un format
        court est à privilégier.<br />
        3. Faites-nous parvenir le résultat à l'adresse mail :
        theatreduroidecoeur@mailo.com.<br />
        4. Veillez à ce que l'envoi ne soit pas trop lourd afin qu'il ne s'égare
        pas.
      </p>
    </v-col>
    <v-col cols="12">
      <h3 class="text-h5 primary--text text-uppercase">Nos récits</h3>
    </v-col>
    <v-col cols="12" class="mt-5 mb-12">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="pr-10 mb-5"
          v-for="s in stories"
          :key="s.id"
        >
          <a
            :href="
              $router.resolve({
                name: 'houseLibraryItem',
                params: { slug: s.slug, id: s.id }
              }).href
            "
          >
            <v-img width="100%" aspect-ratio="1" :src="s.acf.image"></v-img>
            <h5
              class="text-h5 black--text text-center mt-5"
              v-html="s.title.rendered"
            ></h5>
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "Library",

  data: () => ({
    stories: []
  }),

  mounted() {
    apiService.getAll("wp/v2/library").then(data => {
      this.stories = data;
    });
  }
};
</script>
