<template>
  <v-slide-group class="pa-4" next-icon="$arrowNext" prev-icon="$arrowBack">
    <v-slide-item
      v-for="p in partners"
      :key="p.id"
      style="width:120px"
      class="ma-8"
    >
      <a :href="p.acf.link" target="_blank">
        <v-img
          contain
          style="width:100%"
          :src="p.acf.image"
        ></v-img>
      </a>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import { APIService } from "../../APIService";
const apiService = new APIService();

export default {
  name: "PartnersSlider",

  data: () => ({
    partners: []
  }),

  mounted() {
    apiService.getAll("wp/v2/partners").then(data => {
      console.log(data)
      this.partners = data;
    });
  }
};
</script>
