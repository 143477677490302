var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-12"},_vm._l((_vm.dates),function(date){return _c('AgendaCard',{key:date.id,attrs:{"title":date.play ? date.play.title : date.event ? date.event.title : null,"text":date.play ? date.play.resum : date.event ? date.event.body : null,"date":date.start_date,"city":date.house ? 'Le Foyer' : date.city,"place":date.place,"imgUrl":`${_vm.apipath}${
        date.play
          ? date.play.photo.moyen
          : date.event
          ? date.event.photo.moyen
          : null
      }`,"thePlay":date.play ? date.play : date.event ? date.event : null,"type":date.event ? 'evenement' : 'spectacle'}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }